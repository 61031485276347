const config = {
  apiKey: "AIzaSyDfrHvIL5adKSb6avyWVrHfKcDnYay1XGw",
  authDomain: "bhukyskitchen-2a524.firebaseapp.com",
  projectId: "bhukyskitchen-2a524",
  storageBucket: "bhukyskitchen-2a524.appspot.com",
  messagingSenderId: "772186808573",
  appId: "1:772186808573:web:2f33f43452b763e4ee5e94",
  measurementId: "G-26WKBY913N"
};

export default config