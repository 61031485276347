import React from "react";
import "./Splash.css";

function Splash(props) {
  const { Splashr, spanText, className } = props;

  return (
    <div className="se-pre-con"></div>
  );
}

export default Splash;
